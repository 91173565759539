<template>
  <footer class="my-20">
    <p class="text-center text-sm text-slate-500">
      Copyright © {{ new Date().getFullYear() }} AGS(K). All rights reserved.
    </p>
    <!--
      Can we ask you a favor 🙏
      Please keep this backlink on your website if possible.
    -->
    <p class="text-center text-xs text-slate-500 mt-1">
      Made by
      <a
        href="https://github.com/fnmutua/grm"
        target="_blank"
        rel="noopener"
        class="hover:underline"
      >
        Felix Mutua
      </a>
      
    </p>
  </footer>
</template>
